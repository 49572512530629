export default {
  data: [
    {
      id: 'default',
      pt_id: null,
      name: 'Personal Trainer Dashboard',
      api: 'common',
    },
    {
      id: 'demo',
      pt_id: -1,
      name: 'MKGest Demo',
      api: 'common',
    },
    {
      id: 'antoniocamacho',
      pt_id: -7,
      name: 'Antonio Camacho Fitness Coach',
      api: 'common',
    },
    {
      id: 'alibutcher',
      pt_id: -4,
      name: 'Ali Butcher Coaching Online',
      api: 'common',
    },
    {
      id: 'brunolourenco',
      pt_id: -12,
      name: 'Bruno Lourenço PT',
      api: 'common',
    },
    {
      id: 'carlossousa',
      pt_id: -8,
      name: 'Carlos Sousa Training',
      api: 'common',
    },
    {
      id: 'carlosgonzalez',
      pt_id: -20,
      name: 'CG Wellness Coach',
      api: 'common',
    },
    {
      id: 'evolve',
      pt_id: -13,
      name: 'Evolve Raise the Standard',
      api: 'common',
    },
    {
      id: 'franciscoespin',
      pt_id: -14,
      name: 'Francisco Espín',
      api: 'common',
    },
    {
      id: 'helenasousa',
      pt_id: -16,
      name: 'HSF',
      api: 'common',
    },
    {
      id: 'jeremythevenin',
      pt_id: -10,
      name: 'WOLFitness',
      api: 'common',
    },
    {
      id: 'milenemartins',
      pt_id: -9,
      name: 'Milene Martins Fitness',
      api: 'common',
    },
    {
      id: 'nacholopez',
      pt_id: -11,
      name: 'Nacho Lopez Fitness',
      api: 'common',
    },
    {
      id: 'pedropereira',
      pt_id: -2,
      name: 'Pedro Pereira PT',
      api: 'common',
    },
    {
      id: 'raquelsampaio',
      pt_id: -6,
      name: 'Raquel Sampaio PT',
      api: 'common',
    },
    {
      id: 'ruipinheiro',
      pt_id: -15,
      name: 'Rui Pinheiro Online Coaching',
      api: 'common',
    },
    {
      id: 'shapecreators',
      pt_id: -18,
      name: 'Shapecreators',
      api: 'common',
    },
    {
      id: 'teamruiribeiro',
      pt_id: -5,
      name: 'Team Rui Ribeiro',
      api: 'common',
    },
    {
      id: 'topathlete',
      pt_id: -21,
      name: 'The Athlete',
      api: 'common',
    },
    {
      id: 'ptmiguelsantos',
      pt_id: -17,
      name: 'ptmiguelsantos',
      api: 'common',
    },
    {
      id: 'tiagoguimaraes-es',
      pt_id: -3,
      name: 'Tiago Guimaraes Coaching Online (ES)',
      api: 'tiago_guimaraes_es',
      linkParameters: '&cfg=tiagoguimaraes-es&lng=es',
    },
    {
      id: 'tiagoguimaraes',
      pt_id: -3,
      name: 'Tiago Guimaraes Coaching Online (PT)',
      api: 'tiago_guimaraes',
    },
    {
      id: 'tinaaraujo',
      pt_id: -19,
      name: 'Tina Araujo Coaching',
      api: 'common',
    },
    {
      id: 'edgargomes',
      pt_id: -22,
      name: 'Edgar Gomes Sports Coach',
      api: 'common',
    },
    {
      id: 'renatoestevao',
      pt_id: -23,
      name: 'Renato Estêvão Fitness',
      api: 'common',
    },
    {
      id: 'simaoalves',
      pt_id: -24,
      name: 'Simão Alves Online Coaching',
      api: 'common',
    },
    {
      id: 'bonycassama',
      pt_id: -25,
      name: 'BC360 by Bony Cassama',
      api: 'common',
    },
    {
      id: 'rubengarradas',
      pt_id: -26,
      name: 'Ruben Garradas',
      api: 'common',
    },
    {
      id: 'teamveloso',
      pt_id: -27,
      name: 'Team Veloso',
      api: 'common',
    },
    {
      id: 'franciscomacau',
      pt_id: -28,
      name: 'Francisco Macau',
      api: 'francisco_macau',
      logo: 'logo.png',
    },
    {
      id: 'rize',
      pt_id: -29,
      name: 'Rize',
      api: 'rize',
    },
    {
      id: 'mcteam',
      pt_id: -30,
      name: 'MCTEAM',
      api: 'common',
    },
    {
      id: 'naosejasmorcao',
      pt_id: -31,
      name: 'Não Sejas Morcão',
      api: 'common',
    },
    {
      id: '2fitness',
      pt_id: -32,
      name: '2FITNESS',
      api: 'common',
    },
    {
      id: 'active',
      pt_id: -33,
      name: 'Active',
      api: 'common',
    },
    {
      id: 'teamlino',
      pt_id: -34,
      name: 'Team Lino Online Coaching',
      api: 'common',
    },
    {
      id: 'gomafit',
      pt_id: -35,
      name: 'Goma Fit Online Coaching',
      api: 'common',
    },
    {
      id: 'fitfusion',
      pt_id: -36,
      name: 'FitFusion',
      api: 'common',
    },
    {
      id: 'patriciacunha',
      pt_id: -37,
      name: 'Patrícia Cunha',
      api: 'common',
    },
    {
      id: 'nbsteam',
      pt_id: -38,
      name: 'NBS Team',
      api: 'common',
    },
    {
      id: 'luisrocha',
      pt_id: -39,
      name: 'Luis Rocha - Personal Trainer & Coach',
      api: 'common',
    },
    {
      id: 'f92',
      pt_id: -40,
      name: 'F92 Coaching',
      api: 'common',
    },
    {
      id: 'pedromoreira',
      pt_id: -41,
      name: 'Pedro Moreira - Acompanhamento',
      api: 'common',
    },
    {
      id: 'renatomendes',
      pt_id: -42,
      name: 'FLEXFIT',
      api: 'common',
    },
    {
      id: 'paulabarbosa',
      pt_id: -43,
      name: 'Team Paula Barbosa',
      api: 'common',
    },
    {
      id: 'team22',
      pt_id: -44,
      name: 'TEAM 22',
      api: 'common',
    },
    {
      id: 'joaocatita',
      pt_id: -45,
      name: 'Vital Online',
      api: 'common',
    },
  ],
}
