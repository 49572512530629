var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-material-card',_vm._g(_vm._b({staticClass:"v-card--material-chart",class:{ 'no-shadow': !_vm.shadow },scopedSlots:_vm._u([{key:"heading",fn:function(){return [(
        !_vm.options.plugins ||
        (_vm.options.plugins && !_vm.options.plugins.length) ||
        (_vm.options.plugins.length === 1 && _vm.options.plugins[0] !== null) ||
        (_vm.options.plugins.length === 2 && _vm.options.plugins[0] && _vm.options.plugins[1] !== null)
      )?_c('chartist',{style:({
        'max-height': _vm.size === 'auto' ? 'auto' : _vm.size + 'px',
        height: _vm.sizelimit ? _vm.sizelimit + 'px' : '',
      }),attrs:{"data":_vm.data,"event-handlers":_vm.eventHandlers,"options":_vm.options,"ratio":_vm.ratio,"responsive-options":_vm.responsiveOptions,"type":_vm.type}}):_vm._e(),(
        _vm.loading && _vm.options && _vm.options.plugins && _vm.options.plugins.length && (_vm.options.plugins[0] === null || _vm.options.plugins[1] === null)
      )?_c('div',{staticClass:"row-align-center",style:({
        'max-height': _vm.size === 'auto' ? 'auto' : _vm.size + 'px',
        height: _vm.sizelimit ? _vm.sizelimit + 'px' : '',
      })},[_c('v-progress-circular',{attrs:{"size":50,"indeterminate":"","color":"primary"}})],1):_vm._e()]},proxy:true}])},'base-material-card',_vm.$attrs,false),_vm.$listeners),[_vm._t("reveal-actions",null,{"slot":"reveal-actions"}),_vm._t("default"),_vm._t("actions",null,{"slot":"actions"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }