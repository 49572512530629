import axios from 'axios'
import moment from 'moment'
import JSZip from 'jszip'
import pako from 'pako'
import Admin from '@/admin/Admin'
import Api from '@/services/Api'

function handleSetStorage(key, data) {
  if (!window.dashboardSessionStorage) {
    window.dashboardSessionStorage = {}
  }
  window.dashboardSessionStorage[key] = data
}

function handleGetStorage(key) {
  if (window.dashboardSessionStorage) {
    return window.dashboardSessionStorage[key]
  }
  return null
}

function setLabelsStrings(src) {
  for (const key in src) {
    if (Array.isArray(src[key])) {
      for (let i = 0; i < src[key].length; i++) {
        if (src[key][i].label && window.strings && window.strings[src[key][i].label]) {
          src[key][i].label = window.strings[src[key][i].label]
        }
      }
    } else {
      if (typeof src[key] === 'object') {
        setLabelsStrings(src[key])
      }
    }
  }
}

function handleProcessResponse(args) {
  const self = args.self
  const response = args.response
  const requestResponse = args.requestResponse
  const callback = args.callback
  const cacheKey = args.cacheKey

  try {
    const responseData = requestResponse
      ? JSON.parse(requestResponse)
      : {
          success: false,
          data: null,
          message: self.getMessageError(response),
        }

    if (cacheKey && responseData && responseData.success) {
      self.saveRequestCache(cacheKey, responseData)
    }

    const res = {
      success: responseData ? responseData.success : false,
      data: responseData ? (responseData.data ? responseData.data : responseData) : null,
      items_count: responseData ? responseData.items_count : null,
      message: responseData ? (responseData.message ? responseData.message : responseData.detail) : null,
    }

    if (callback) {
      callback(res)
    }

    return res
  } catch {
    const res = {
      success: false,
      message: self.getMessageError(),
    }
    if (callback) {
      callback(res)
    }
    return res
  }
}

export default {
  getUrls: function () {
    const urls = JSON.parse(process.env.VUE_APP_URLS)
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const devIsCommon = urlParams.get('common') ? true : false
    window.useChatV2 = urlParams.get('chat_v2') ? true : false
    let siteKey = window.location.host
      ? window.location.host.indexOf('franciscomacaupersonaltrainer') > -1
        ? 'franciscomacau'
        : window.location.host.split('.')[0]
      : null
    if (urlParams.get('pt_key')) {
      siteKey = urlParams.get('pt_key')
    }

    window.jsonAdmin = Admin.data.find(function (it) {
      return it.id === siteKey
    })
    window.jsonAdminDefault = false
    if (!window.jsonAdmin && devIsCommon) {
      window.jsonAdmin = Admin.data.find(function (it) {
        return it.id === 'default'
      })
      window.jsonAdminDefault = true
    }
    if (!window.jsonAdmin) {
      window.jsonAdmin = Admin.data.find(function (it) {
        return it.id === 'tiagoguimaraes'
      })
      window.jsonAdminDefault = true
    }

    return urls[axios.defaults.dev ? 'dev' : 'prod'][window.jsonAdmin.api]
  },
  updateAuthorization: function (user) {
    axios.defaults.authorization = 'Basic ' + window.btoa(user.email + ':' + user.password)
  },
  refreshUserFields: function (user) {
    axios.defaults.userDbId = parseFloat(user.db_id)
    axios.defaults.userId = parseFloat(user.id)
    axios.defaults.chatLogEmail = user.email
    axios.defaults.chatLogName = user.name

    if (user && user.type === 0) {
      axios.defaults.ptId = parseFloat(user.id)
    } else {
      axios.defaults.ptId = parseFloat(user.pt_id)
    }

    if (user && user.configurations && user.configurations.payment && user.configurations.payment.checkout) {
      window.useCheckout = true
    }

    if (user && user.configurations && user.configurations.payment && user.configurations.payment.hotmart) {
      window.useHotmart = true
    }

    window.employeeIdLogged = null
    if (
      user &&
      user.configurations &&
      user.configurations.employees &&
      user.configurations.employees.assign_clients &&
      this.isEmployee(user.type)
    ) {
      window.employeeIdLogged = user.id
    }

    window.useResetCacheSockets = false
    if (user && user.configurations && user.configurations.mqtt && user.configurations.mqtt.cache) {
      window.useResetCacheSockets = true
    }

    if (user && user.configurations && user.configurations.payment && user.configurations.payment.gateway_type) {
      window.gatewayType = user && user.configurations && user.configurations.payment && user.configurations.payment.gateway_type
    }
  },
  refreshEnums: function (data) {
    if (data) {
      try {
        setLabelsStrings(data)
      } catch {}
      handleSetStorage('enums', data)
    } else {
      handleSetStorage('enums', {})
    }
  },
  getEnums: function () {
    const enums = handleGetStorage('enums')
    if (enums) {
      return enums
    } else {
      return {}
    }
  },
  encodeClient: function (data, newClient) {
    const self = this
    data.photo = newClient ? require('@/assets/user.svg') : require('@/assets/loading.gif')
    data.payday = data.payday ? data.payday : window.strings['n_a']
    data.feedback_day = data.feedback_day ? data.feedback_day : window.strings['n_a']
    data.dbId = data.db_id
    data.gender_label = this.getLabel(this.getGenders(), data.gender)
    if (data.birth_date) {
      const ageDifMs = Date.now() - new Date(data.birth_date).getTime()
      const ageDate = new Date(ageDifMs)
      data.age = Math.abs(ageDate.getUTCFullYear() - 1970)
    }
    if (data.tags && typeof data.tags === 'string') {
      data.tags = data.tags.split(',')
    }
    if (!data.converted_dates) {
      const dateFields = [
        'chat_last_msg',
        'last_feedback_valid',
        'last_login_dt',
        'register_date',
        'status_date',
        'purchase_date',
        'validation_date',
      ]
      dateFields.forEach(function (f) {
        if (data[f]) {
          data[f] = self.convertUtcDate(data[f])
        }
      })
      data.converted_dates = true
    }
    try {
      if (data.notes) {
        const notes = typeof data.notes === 'string' ? JSON.parse(data.notes) : data.notes
        if (notes && notes.device_model) {
          data.device_model = notes.device_model
        }
        if (notes && notes.position) {
          data.position = window.strings[notes.position] ? window.strings[notes.position] : notes.position
        }
      }
    } catch {}
    return data
  },
  getUser: function () {
    try {
      const user = handleGetStorage('user')
      return user
    } catch (error) {
      return null
    }
  },
  processResponse: function (response, callback, cacheKey) {
    const self = this
    try {
      if (response && response.config && response.config.responseType === 'arraybuffer') {
        this.uncompressData(response.request.response, function (responseData) {
          handleProcessResponse({
            self: self,
            response: response,
            requestResponse: responseData,
            callback: callback,
            cacheKey: cacheKey,
          })
        })
      } else {
        return handleProcessResponse({
          self: self,
          response: response,
          requestResponse: response.request.response,
          callback: callback,
          cacheKey: cacheKey,
        })
      }
    } catch (error) {
      const res = {
        success: false,
        message: self.getMessageError(),
      }
      if (callback) {
        callback(res)
      }
      return res
    }
  },
  uncompressData: function (data, callback) {
    try {
      const uint8Array = new Uint8Array(data)
      callback(pako.inflate(uint8Array, { to: 'string' }))
    } catch (error) {
      const zip = new JSZip()
      zip
        .loadAsync(data, { base64: false })
        .then((zip) => {
          zip.forEach((relativePath, file) => {
            file.async('string').then((content) => {
              callback(content)
            })
          })
        })
        .catch(() => {
          callback(
            JSON.stringify({
              success: false,
              message: window.strings['common_error'],
            }),
          )
        })
    }
  },
  processGetUser: function (response, callback) {
    try {
      const responseData = response.request.response
        ? JSON.parse(response.request.response)
        : {
            success: false,
            data: null,
            message: this.getMessageError(response),
          }
      callback({
        success: responseData ? responseData.success : false,
        data: responseData && responseData.data && responseData.data[0] ? this.encodeUser(responseData.data[0]) : null,
        message: responseData ? responseData.message : null,
      })
    } catch (error) {
      callback({
        success: false,
        message: this.getMessageError(),
      })
    }
  },
  getMessageError: function (response) {
    let messageError =
      window.strings && window.strings['server_connection_failed']
        ? window.strings['server_connection_failed']
        : 'Parece que estamos a enfrentar alguns problemas ao estabelecer ligação com o servidor. Por favor, verifica a ligação à internet ou aguarda um pouco antes de tentar novamente.'
    if (response && response.message && response.message !== 'Network Error' && response.code !== 'ECONNABORTED') {
      messageError = response.message
    }
    return messageError
  },
  getRequestCache: function (cacheKey, data) {
    if (data.ids || data.minType || data.photo || data.fields) {
      return false
    }
    if (data.type) {
      cacheKey += '-' + data.type
    }
    if (data.useCache && window.cacheRequests && window.cacheRequests[cacheKey]) {
      return window.cacheRequests[cacheKey]
    }
    data.cacheKey = cacheKey
  },
  saveRequestCache: function (cacheKey, data) {
    if (!window.cacheRequests) {
      window.cacheRequests = {}
    }
    window.cacheRequests[cacheKey] = data
  },
  removeRequestCache: function (cacheKey) {
    if (window.cacheRequests) {
      if (cacheKey) {
        for (const key in window.cacheRequests) {
          if (key.indexOf(cacheKey) > -1) {
            delete window.cacheRequests[key]
          }
        }
      } else {
        window.cacheRequests = {}
      }
    }
  },
  encodeUser: function (data) {
    data.photo = data.photo ? data.photo : null
    this.refreshUserFields(data)
    return data
  },
  getLabel: function (items, value) {
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].value === value) {
          return items[i].label
        }
      }
    }
  },
  getGenders: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.genders
    } else {
      return []
    }
  },
  getPhysicalConditions: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.physical_condition
    } else {
      return []
    }
  },
  getGoals: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.goals
    } else {
      return []
    }
  },
  getMuscleGroups: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.muscle_groups
    } else {
      return []
    }
  },
  getWorkoutLevels: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.workout_levels
    } else {
      return []
    }
  },
  getWorkoutTargets: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.workout_targets
    } else {
      return []
    }
  },
  getWorkoutTypes: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.workout_types
    } else {
      return []
    }
  },
  getWorkoutEquipments: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.workout_equipments
    } else {
      return []
    }
  },
  getWorkoutSeries: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.workout_series
    } else {
      return []
    }
  },
  getWorkoutCategories: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.workout_categories
    } else {
      return []
    }
  },
  getWorkoutPlaces: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.workout_places
    } else {
      return []
    }
  },
  getWorkoutFlags: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.workout_flags
    } else {
      return []
    }
  },
  getStepSeries: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.step_series
    } else {
      return []
    }
  },
  getStepReps: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.step_reps
    } else {
      return []
    }
  },
  getStepRest: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.step_rest
    } else {
      return []
    }
  },
  getStepRpe: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.step_rpe
    } else {
      return []
    }
  },
  getTrngPlanPlaces: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.trng_plan_places
    } else {
      return []
    }
  },
  getTrngPlanLevels: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.trng_plan_levels
    } else {
      return []
    }
  },
  getTrngPlanGenders: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.trng_plan_genders
    } else {
      return []
    }
  },
  getTrngPlanTypes: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.trng_plan_types
    } else {
      return []
    }
  },
  getTrngPlanTotalTrains: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.trng_plan_total_trains
    } else {
      return []
    }
  },
  getTrngPlanDurations: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.trng_plan_duration
    } else {
      return []
    }
  },
  getTrngPlanEquipment: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.trng_plan_equipment
    } else {
      return []
    }
  },
  getTrngPlanDayCategories: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.trng_plan_day_categories
    } else {
      return []
    }
  },
  getSegmentedTrainingTypes: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.segmented_training_types
    } else {
      return []
    }
  },
  getFoodTypes: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.food_types
    } else {
      return []
    }
  },
  getSupplementsChoices: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.supplements_choices
    } else {
      return []
    }
  },
  getHealthProblems: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.health_problems
    } else {
      return []
    }
  },
  getNutritionTypes: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.nutrition_types
    } else {
      return []
    }
  },
  getPromoCodesPlanTypes: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.promo_codes_plan_types
    } else {
      return []
    }
  },
  getPromoCodesSources: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.promo_codes_sources
    } else {
      return []
    }
  },
  getContentTypes: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.content_types
    } else {
      return []
    }
  },
  getContentSubTypes: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.content_sub_types
    } else {
      return []
    }
  },
  getContentType: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.content_type
    } else {
      return []
    }
  },
  getRegisterStatus: function () {
    return [
      {
        value: 0,
        label: window.strings['to_validate'],
        type: 'warning',
      },
      {
        value: 1,
        label: window.strings['validated'],
        open_message_error: window.strings['register_already_validated'],
        type: 'success',
      },
      {
        value: 2,
        label: window.strings['refused'],
        open_message_error: window.strings['register_already_refused'],
        type: 'error',
      },
    ]
  },
  getRegisterPaymentStatus: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.register_payment_status
    } else {
      return []
    }
  },
  getWeekdays: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.week_days
    } else {
      return []
    }
  },
  getFeedbackFrequencies: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.feedback_frequencies
    } else {
      return []
    }
  },
  getFeedbackWeeks: function () {
    const total = 52
    const weeks = []
    for (let i = 0; i < total; i++) {
      const val = (i + 1).toString()
      weeks.push({
        label: window.strings['week'] + ' ' + val,
        value: val,
      })
    }
    return weeks
  },
  getFeedbackTypes: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.feedback_types
    } else {
      return []
    }
  },
  getFeedbackTypesDict: function () {
    return {
      default: 0,
      return: 4,
      newplans: 5,
      diary: 6,
      initial_quiz: 99,
    }
  },
  getFoodParentCategories: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.food_parent_categories
    } else {
      return []
    }
  },
  getFoodCategories: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.food_categories
    } else {
      return []
    }
  },
  getRecipeCategories: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.recipe_categories
    } else {
      return []
    }
  },
  getRecipeParentCategories: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.recipe_parent_categories
    } else {
      return []
    }
  },
  getNutritionCategories: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.nutrition_categories
    } else {
      return []
    }
  },
  getNutritionCategories2: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.nutrition_categories2
    } else {
      return []
    }
  },
  getNutritionNumberOfMeals: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.nutrition_number_of_meals
    } else {
      return []
    }
  },
  getNutritionMealsLessTime: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.nutrition_meals_less_time
    } else {
      return []
    }
  },
  getNutritionExcludeRecipesDescription: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.nutrition_exclude_recipes_description
    } else {
      return []
    }
  },
  getNutritionGoals: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.nutrition_goals
    } else {
      return []
    }
  },
  getPregnantOptions: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.pregnant_options
    } else {
      return []
    }
  },
  getFoodPreferences: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.food_preferences
    } else {
      return []
    }
  },
  getFoodVegetarian: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.food_vegetarian
    } else {
      return []
    }
  },
  getFoodIntolerance: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.food_intolerance
    } else {
      return []
    }
  },
  getFoodGroups: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.food_groups
    } else {
      return []
    }
  },
  getActivityLevels: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.activity_levels
    } else {
      return []
    }
  },
  getPhysicalHealthProblems: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.physical_health_problems
    } else {
      return []
    }
  },
  getTriedChangeBody: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.tried_change_body
    } else {
      return []
    }
  },
  getClientExpectations: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.client_expectations
    } else {
      return []
    }
  },
  getTrainEvents: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.train_events
    } else {
      return []
    }
  },
  getLiveDaysOfWeek: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.live_days_of_week
    } else {
      return []
    }
  },
  getPaymentTypes: function () {
    return [
      {
        value: 0,
        label: window.strings['proof'],
        type: 'proof',
      },
      {
        value: 1,
        label: window.strings['stripe_subscription'],
        type: 'stripe',
        pack_fields: ['name', 'status', 'temp', 'start_date', 'end_date', 'url', 'contents', 'employees'],
      },
      {
        value: 2,
        label: window.strings['checkout_subscription'],
        type: 'checkout',
      },
      {
        value: 3,
        label: window.strings['hotmart_subscription'],
        type: 'hotmart',
        pack_fields: ['name', 'status', 'temp', 'start_date', 'end_date', 'url', 'contents', 'employees'],
      },
      {
        value: 4,
        label: window.strings['viva_wallet'],
        type: 'viva_wallet',
        pack_fields: [
          'name',
          'status',
          'price',
          'signup_fee',
          'type',
          'category',
          'charge_day',
          'allow_recurring',
          'allow_one_time_payments',
          'allow_installments',
          'temp',
          'allow_contents',
          'start_date',
          'end_date',
          'moloni_product',
          'contents',
          'employees',
          'description',
          'form',
        ],
        get_client_subscription: true,
        convert_promotional_code_price: true,
        partnership_reg: true,
        payments_table_headers: [
          {
            value: 'description',
            text: 'payment_method',
          },
        ],
        client_paym_error: {
          field: 'paym_check',
          values: [7],
        },
      },
      {
        value: 98,
        label: window.strings['promo_free'],
        type: 'promo_free',
      },
      {
        value: 99,
        label: window.strings['manual'],
        type: 'manual',
      },
      {
        value: 100,
        label: window.strings['manual_return'],
        type: 'manual_return',
      },
      {
        value: 101,
        label: window.strings['subscription_return'],
        type: 'subscription_return',
      },
    ]
  },
  getPaymentTypeValue: function (type) {
    const list = this.getPaymentTypes()
    if (list && list.length) {
      const item = list.find(function (item) {
        return item.type === type
      })
      if (item) {
        return item.value
      }
    }
    return null
  },
  getUserStatus: function (changeStatus) {
    const enums = this.getEnums()
    if (enums && enums.user_status && enums.user_status.length) {
      return enums.user_status
    } else {
      let items = []
      if (changeStatus) {
        items = [
          {
            value: 0,
            label: window.strings['active'],
            type: 'success',
          },
          {
            value: 1,
            label: window.strings['suspended'],
            type: 'yellow',
          },
          {
            value: 2,
            label: window.strings['blocked'],
            type: 'error',
          },
        ]
      } else {
        items = [
          {
            value: 0,
            label: window.strings['active'],
            type: 'success',
          },
          {
            value: 1,
            label: window.strings['suspended'],
            type: 'yellow',
          },
          {
            value: 2,
            label: window.strings['blocked'],
            type: 'error',
          },
          {
            value: 3,
            label: window.strings['pending'],
            type: 'blue',
          },
          {
            value: 4,
            label: window.strings['pending_login'],
            type: 'blue',
          },
          {
            value: 5,
            label: window.strings['suspended_payment_pending'],
            type: 'warning',
          },
          {
            value: 6,
            label: window.strings['pending_return'],
            type: 'warning',
          },
          {
            value: 7,
            label: window.strings['pending_promo_code'],
            type: 'warning',
          },
          {
            value: 99,
            label: window.strings['pending_initial_quiz'],
            type: 'warning',
          },
        ]
      }
      const user = handleGetStorage('user')
      const configStatus = user && user.configurations && user.configurations.client_status ? user.configurations.client_status : null
      if (configStatus) {
        for (let i = 0; i < items.length; i++) {
          if (configStatus[items[i].value]) {
            if (configStatus[items[i].value].label) {
              items[i].label = window.strings[configStatus[items[i].value].label]
                ? window.strings[configStatus[items[i].value].label]
                : configStatus[items[i].value].label
            }
            if (configStatus[items[i].value].type) {
              items[i].type = configStatus[items[i].value].type
            }
          }
        }
      }
      return items
    }
  },
  getCalendarStatus: function () {
    return [
      {
        value: 0,
        label: window.strings['active'],
        status: 'active',
        type: 'success',
      },
      {
        value: 1,
        label: window.strings['pending'],
        status: 'pending',
        type: 'yellow',
      },
    ]
  },
  getBookingScheduleStatus: function () {
    return [
      {
        value: 'active',
        label: window.strings['active'],
        type: 'success',
      },
      {
        value: 'pending',
        label: window.strings['pending'],
        type: 'warning',
      },
      {
        value: 'deleted',
        label: window.strings['deleted'],
        type: 'error',
      },
    ]
  },
  getBookingStatus: function () {
    return [
      {
        value: 'confirmed',
        label: window.strings['confirmed'],
        type: 'success',
      },
      {
        value: 'canceled',
        label: window.strings['canceled'],
        type: 'error',
      },
      {
        value: 'pending',
        label: window.strings['pending'],
        type: 'warning',
      },
      {
        value: 'waitlist',
        label: window.strings['waitlist'],
        type: 'warning',
      },
      {
        value: 'deleted',
        label: window.strings['deleted'],
        type: 'error',
      },
    ]
  },
  getBookingScheduleTypes: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.booking_schedule_types
    } else {
      return []
    }
  },
  getPaymentStatus: function () {
    return [
      {
        value: 0,
        type: 'warning',
        label: window.strings['to_validate'],
      },
      {
        value: 1,
        type: 'success',
        label: window.strings['validated'],
      },
      {
        value: 2,
        type: 'error',
        label: window.strings['refused'],
      },
      {
        value: 3,
        type: 'error',
        label: 'ChargeBack',
      },
      {
        value: 4,
        type: 'blue',
        label: window.strings['refunded'],
      },
    ]
  },
  getPlanStatus: function () {
    return [
      {
        value: 1,
        type: 'warning',
        status: 'active',
        label: window.strings['progressing'],
      },
      {
        value: 2,
        type: 'success',
        status: 'finished',
        label: window.strings['finished'],
      },
      {
        value: 3,
        type: 'error',
        status: 'cancelled',
        label: window.strings['cancelled'],
      },
      {
        value: 4,
        type: 'blue',
        status: 'pending',
        label: window.strings['pending'],
      },
    ]
  },
  getPhysicalEvaluationStatus: function () {
    return [
      {
        value: 0,
        type: 'warning',
        status: 'to_validate',
        label: window.strings['to_validate'],
      },
      {
        value: 1,
        type: 'success',
        status: 'validated',
        label: window.strings['validated'],
      },
      {
        value: 2,
        type: 'warning',
        status: 'initial_quiz',
        label: window.strings['to_validate'],
      },
      {
        value: 3,
        type: 'info',
        status: 'progressing',
        label: window.strings['progressing'],
      },
      {
        value: 4,
        type: 'info',
        status: 'unread',
        label: window.strings['validated_unread_feedback'],
      },
    ]
  },
  getNotificationsStatus: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.notifications_status
    } else {
      return []
    }
  },
  getEmployeeScheduleStatus: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.employee_schedule_status
    } else {
      return []
    }
  },
  getEmployeeScheduleDays: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.employee_schedule_days
    } else {
      return []
    }
  },
  getEmployeeHistoricCategories: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.employee_historic_categories
    } else {
      return []
    }
  },
  getEmployeeSubTypes: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.employee_sub_types
    } else {
      return []
    }
  },
  getChatHistoricCategories: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.chat_historic_categories
    } else {
      return []
    }
  },
  getPaymentMethods: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.payment_methods
    } else {
      return []
    }
  },
  getUserTags: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.user_tags
    } else {
      return []
    }
  },
  getFeedbackRating: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.feedback_rating
    } else {
      return []
    }
  },
  getReportIssueTypes: function () {
    return [
      {
        value: 0,
        status: 'none',
        label: window.strings['n_a'],
      },
      {
        value: 1,
        status: 'bug',
        label: window.strings['bug'],
        type: 'error',
      },
      {
        value: 2,
        status: 'user_error',
        label: window.strings['user_error'],
        type: 'yellow',
      },
      {
        value: 3,
        status: 'feature_request',
        label: window.strings['feature_request'],
        type: 'success',
      },
      {
        value: 4,
        status: 'administrative_issue',
        label: window.strings['administrative_issue'],
        type: 'warning',
      },
    ]
  },
  getReportIssueStatus: function () {
    return [
      {
        value: 0,
        status: 'open',
        label: window.strings['opened'],
        type: 'error',
      },
      {
        value: 1,
        status: 'in_progress',
        label: window.strings['progressing'],
        type: 'warning',
      },
      {
        value: 2,
        status: 'resolved',
        label: window.strings['resolved'],
        type: 'yellow',
      },
      {
        value: 3,
        status: 'closed',
        label: window.strings['closed'],
        type: 'success',
      },
    ]
  },
  getTaskStatus: function () {
    return [
      {
        value: 0,
        status: 'open',
        label: window.strings['opened'],
        type: 'warning',
      },
      {
        value: 1,
        status: 'in_progress',
        label: window.strings['progressing'],
        type: 'yellow',
      },
      {
        value: 3,
        status: 'closed',
        label: window.strings['closed'],
        type: 'success',
      },
    ]
  },
  getPaymentsPacksStatus: function () {
    return [
      {
        value: 0,
        label: window.strings['active'],
        type: 'success',
        status: 'active',
      },
      {
        value: 1,
        label: window.strings['deactivated'],
        type: 'error',
      },
    ]
  },
  getPaymentsPacksTypes: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.payments_packs_types
    } else {
      return []
    }
  },
  getPaymentsPacksCategories: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.payments_packs_categories
    } else {
      return []
    }
  },
  getPaymentsPacksForms: function () {
    const enums = this.getEnums()
    if (enums) {
      return enums.payments_packs_forms
    } else {
      return []
    }
  },
  getWhatsappMessages: function (key) {
    const enums = this.getEnums()
    if (enums) {
      return enums[key]
    } else {
      return []
    }
  },
  getYoutubeEmbedUrl: function (url) {
    url = url.trim()
    let videoId = ''
    let splitId = url.split('v=')[1]
    if (splitId) {
      const ampersandPosition = splitId.indexOf('&')
      if (ampersandPosition !== -1) {
        videoId = splitId.substring(0, ampersandPosition)
      } else {
        videoId = splitId
      }
    } else {
      splitId = url.split('.be/')[1]
      if (splitId) {
        videoId = splitId
      }
    }
    if (url.indexOf('/live/') > -1) {
      videoId = url.split('/live/')[1]
    }
    return 'https://www.youtube.com/embed/' + videoId
  },
  splitArray: function (array, size) {
    const chunkedArr = []

    for (let i = 0; i < array.length; i++) {
      const last = chunkedArr[chunkedArr.length - 1]
      if (!last || last.length === size) {
        chunkedArr.push([array[i]])
      } else {
        last.push(array[i])
      }
    }

    return chunkedArr
  },
  getFormatDate: function (date) {
    let d = new Date()

    if (date) {
      d = date
    }

    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = d.getFullYear()

    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + day
    }

    return [year, month, day].join('-')
  },
  getFormatDateHour: function (date, utc, format) {
    let d = moment()
    if (utc) {
      d = moment.utc()
    }
    if (date) {
      if (utc) {
        const utcMoment = moment.utc(date, 'YYYY-MM-DD HH:mm:ss')
        d = utcMoment.local()
      } else {
        d = moment(date)
      }
    }
    return d.format(format ? format : 'YYYY-MM-DD HH:mm:ss')
  },
  convertUtcDate: function (date, format) {
    try {
      if (date.indexOf(':') > -1) {
        let utcDate = date.replace(' ', 'T')
        if (utcDate.split(':').length < 3) {
          utcDate += ':00'
        }
        utcDate += 'Z'
        if (format) {
          return moment(utcDate).utcOffset(moment().utcOffset()).format(format)
        } else {
          return moment(utcDate).utcOffset(moment().utcOffset()).format('YYYY-MM-DD HH:mm')
        }
      } else {
        return date
      }
    } catch (error) {
      return date
    }
  },
  convertDateToUtc: function (date) {
    try {
      const localMoment = moment(date, 'YYYY-MM-DD HH:mm')
      return localMoment.utc().format('YYYY-MM-DD HH:mm')
    } catch (error) {
      return date
    }
  },
  getWeekDay: function (momentDate) {
    const num = momentDate.isoWeekday()
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
    return days[num - 1] && window.strings[days[num - 1]] ? window.strings[days[num - 1]] : ''
  },
  hasPermission: function (type, id) {
    try {
      const user = handleGetStorage('user')
      if (user && user.permissions && user.permissions.length) {
        if (type === 'menu') {
          switch (id) {
            case 'clients':
              return getPermission(user.permissions, 'client_list_view')
            case 'training_plans_base':
            case 'pdf_training_plans_base':
            case 'plans_base':
            case 'trains_base':
              return getPermission(user.permissions, 'base_trng_plans_view')
            case 'workouts':
              return getPermission(user.permissions, 'workouts_view')
            case 'nutrition_base':
            case 'food_plan_base':
            case 'diets_base':
              return getPermission(user.permissions, 'base_nutrition_view')
            case 'recipes':
              return getPermission(user.permissions, 'receipt_list_view')
            case 'foods':
              return getPermission(user.permissions, 'food_list_view')
            case 'supplements':
              return getPermission(user.permissions, 'supplement_list_view')
            case 'contents':
              return getPermission(user.permissions, 'content_list_view')
            case 'employees':
              return getPermission(user.permissions, 'employees_view')
            case 'payments_packs':
              return getPermission(user.permissions, 'payments_packs_view')
            case 'promotional_codes':
              return getPermission(user.permissions, 'promo_codes_view')
            case 'calendar':
              return getPermission(user.permissions, 'calendar_view')
            case 'chat':
              return getPermission(user.permissions, 'chat_view')
            case 'mkt_clint':
              return getPermission(user.permissions, 'mkt_clint_view')
            case 'partnerships':
              return false
            case 'statistics':
              return getPermission(user.permissions, 'statistics_view')
            case 'tutorials':
              return getPermission(user.permissions, 'tutorials_view')
            case 'notifications':
              return getPermission(user.permissions, 'notifications_view')
            case 'lives':
              return getPermission(user.permissions, 'lives_view')
            case 'scouting':
              return getPermission(user.permissions, 'scouting_view')
            case 'tasks':
              return getPermission(user.permissions, 'task_view')
          }
        } else if (type === 'client') {
          switch (id) {
            case 'training_plan':
            case 'plans':
            case 'trains':
              return getPermission(user.permissions, 'trng_plans_view')
            case 'nutrition_plan':
            case 'food_plan':
            case 'diets':
              return getPermission(user.permissions, 'nutrition_view')
            case 'physical_evaluation':
              return getPermission(user.permissions, 'client_feedbacks_view')
            case 'payments':
              return getPermission(user.permissions, 'payments_view')
            case 'notifications':
              return getPermission(user.permissions, 'notifications_view')
            case 'calendar':
              return getPermission(user.permissions, 'calendar_client_view')
            case 'documents':
              return getPermission(user.permissions, 'documents_client_view')
          }
        } else {
          return getPermission(user.permissions, type)
        }
      }
      return true
    } catch (error) {
      return true
    }

    function getPermission(permissions, permissionId) {
      for (let i = 0; i < permissions.length; i++) {
        if (permissions[i].name === permissionId) {
          if (permissions[i].value === '1') {
            return true
          } else {
            return false
          }
        }
      }
      return true
    }
  },
  encodeEmail: function (email) {
    return email.toLowerCase().replace(/ /g, '')
  },
  isEmployee: function (userType) {
    return userType === 2 ? true : false
  },
  getAlertOptions: function (confirmation, warning, html, texts) {
    return {
      confirmButtonText: texts && texts.confirm ? texts.confirm : confirmation ? window.strings['yes'] : window.strings['ok'],
      cancelButtonText: texts && texts.cancel ? texts.cancel : confirmation ? window.strings['no'] : window.strings['cancel'],
      confirmButtonColor: warning ? '#fb8c00' : '#509dc8',
      html: html ? html : null,
    }
  },
  getCurrency: function (data) {
    try {
      if (data.currency) {
        return data.currency
      }
      const user = handleGetStorage('user')
      const config = user && user.configurations ? user.configurations : null
      if (config && config.currencies) {
        for (let i = 0; i < config.currencies.length; i++) {
          if (config.currencies[i].values && config.currencies[i].values.indexOf(data.value) > -1) {
            return ' ' + config.currencies[i].currency
          }
        }
      }
    } catch {}
    return ' €'
  },
  clearStorage: function () {
    window.dashboardSessionStorage = {}
  },
  isMobile: function () {
    return (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.platform)
    )
  },
  exportCsv: function (args) {
    const rows = []
    let csvContent = ''

    if (args.content) {
      csvContent = args.content
    } else {
      if (!args.hideHeaders) {
        const row = []
        args.headers.forEach(function (header) {
          row.push('"' + (header.text ? header.text : header) + '"')
        })
        rows.push(row)
      }

      for (let i = 0; i < args.data.length; i++) {
        const row = []
        const item = args.data[i]
        args.headers.forEach(function (header) {
          let value = ''
          if (header.parent) {
            if (item[header.parent]) {
              item[header.parent] = typeof item[header.parent] === 'string' ? JSON.parse(item[header.parent]) : item[header.parent]
              const parentValue = item[header.parent].find(function (p) {
                if (
                  p.id &&
                  ((!Array.isArray(header.value) && p.id === header.value) ||
                    (Array.isArray(header.value) && header.value.indexOf(p.id) > -1))
                ) {
                  return true
                }
                if (
                  p.title &&
                  ((!Array.isArray(header.value) && p.title === header.value) ||
                    (Array.isArray(header.value) && header.value.indexOf(p.title) > -1))
                ) {
                  return true
                }
                return false
              })
              if (parentValue) {
                value = parentValue.value ? parentValue.value : parentValue.answer
              }
            }
          } else {
            value = item[header.value ? header.value : header]
            if (args.valueCallback) {
              value = args.valueCallback(header, item, value)
            }
          }
          value =
            '"' + (value ? value.toString().replace(/"/g, '""').replace(/,/g, '\\,').replace(/#/g, '＃').replace(/\n/g, '\\n') : '') + '"'
          row.push(value)
        })
        rows.push(row)
      }

      rows.forEach(function (rowArray) {
        const row = rowArray.join(',')
        csvContent += row + '\r\n'
      })
    }

    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csvcharset=utf-8,' + encodeURI(csvContent)
    hiddenElement.target = '_blank'
    hiddenElement.download = args.title + '.csv'
    hiddenElement.click()
  },
  removeStorage: function (key) {
    if (window.dashboardSessionStorage) {
      delete window.dashboardSessionStorage[key]
    }
  },
  setStorage: function (key, data) {
    handleSetStorage(key, data)
  },
  getStorage: function (key) {
    return handleGetStorage(key)
  },
  openClientChat: function (args) {
    const self = this
    const scope = args.scope
    const client = args.client
    const isRegister = args.isRegister
    const save = args.save
    const user = this.getUser()

    if (user.configurations.chat.version_2) {
      if (client.chat_user_id) {
        window.postMessage(
          JSON.stringify({
            event: 'open-chat-client',
            data: {
              id: client.chat_user_id,
            },
          }),
          '*',
        )
      } else {
        if (scope) {
          scope.$isLoading(true)
        }

        Api.updateUser(
          {
            id: client.user_id ? client.user_id : client.id,
            name: client.name,
          },
          function (response) {
            if (scope) {
              scope.$isLoading(false)
            }

            if (response.success) {
              client.chat_user_id = response.data.chat_user_id

              window.postMessage(
                JSON.stringify({
                  event: 'open-chat-client',
                  data: {
                    id: client.chat_user_id,
                  },
                }),
                '*',
              )

              if (save) {
                self.setStorage('client', client)
              }
            }
          },
        )
      }

      return true
    }

    const message = client.chat_id
      ? {
          event: 'open-chat-client',
          data: {
            id: client.db_id ? client.db_id : client.dbId,
            chat_id: client.chat_id,
            register_id: isRegister ? client.id : null,
          },
        }
      : {
          event: 'open-new-chat-client',
          data: isRegister
            ? {
                register_id: client.id,
              }
            : client.db_id
              ? client.db_id
              : client.dbId,
        }

    window.postMessage(JSON.stringify(message), '*')
  },
  extractEmail: function (invalidEmail) {
    const regex = /([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})/
    const match = regex.exec(invalidEmail)
    if (match) {
      return match[0]
    } else {
      return null
    }
  },
  setTableCache: function (parent, key, value) {
    if (value === null && window.cacheTables && window.cacheTables[parent] && window.cacheTables[parent][key]) {
      delete window.cacheTables[parent][key]
      return
    }
    window.cacheTables = window.cacheTables || {}
    window.cacheTables[parent] = window.cacheTables[parent] || {}
    window.cacheTables[parent][key] = value
  },
  getTableCache: function (parent, key) {
    if (window.cacheTables && window.cacheTables[parent] && window.cacheTables[parent][key]) {
      return window.cacheTables[parent][key]
    }
  },
}
