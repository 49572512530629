<template>
  <base-material-card class="v-card--material-chart" v-bind="$attrs" :class="{ 'no-shadow': !shadow }" v-on="$listeners">
    <template #heading style="height: 400px">
      <chartist
        v-if="
          !options.plugins ||
          (options.plugins && !options.plugins.length) ||
          (options.plugins.length === 1 && options.plugins[0] !== null) ||
          (options.plugins.length === 2 && options.plugins[0] && options.plugins[1] !== null)
        "
        :data="data"
        :event-handlers="eventHandlers"
        :options="options"
        :ratio="ratio"
        :responsive-options="responsiveOptions"
        :type="type"
        :style="{
          'max-height': size === 'auto' ? 'auto' : size + 'px',
          height: sizelimit ? sizelimit + 'px' : '',
        }"
      />
      <div
        v-if="
          loading && options && options.plugins && options.plugins.length && (options.plugins[0] === null || options.plugins[1] === null)
        "
        :style="{
          'max-height': size === 'auto' ? 'auto' : size + 'px',
          height: sizelimit ? sizelimit + 'px' : '',
        }"
        class="row-align-center"
      >
        <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
      </div>
    </template>

    <slot slot="reveal-actions" name="reveal-actions" />

    <slot />

    <slot slot="actions" name="actions" />
  </base-material-card>
</template>

<script>
export default {
  name: 'MaterialChartCard',

  inheritAttrs: false,

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    eventHandlers: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    ratio: {
      type: String,
      default: undefined,
    },
    responsiveOptions: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: () => '150',
    },
    sizelimit: {
      type: String,
      default: () => '',
    },
    shadow: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      required: true,
      validator: (v) => ['Bar', 'Line', 'Pie'].includes(v),
    },
  },
}
</script>

<style lang="sass">
.no-shadow
  margin-bottom: 0 !important
  box-shadow: none !important

.v-card--material-chart
  p
    color: #999

  .v-card--material__heading
    .ct-label
      color: inherit
      opacity: .7
      font-size: 0.975rem
      font-weight: 100

    .ct-grid
      stroke: rgba(255, 255, 255, 0.2)

    .ct-series-a .ct-point,
    .ct-series-a .ct-line,
    .ct-series-a .ct-bar,
    .ct-series-a .ct-slice-donut
      stroke: #26C6DA

    .ct-series-a .ct-slice-pie,
    .ct-series-a .ct-area
      fill: rgba(255,255,255,.4)

    .ct-chart-pie
      .ct-label
        font-weight: 500
        fill: #000
</style>
