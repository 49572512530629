<template>
  <div class="material-stats-card-element elevation-6" :class="[color]">
    <div v-if="icon" class="material-stats-card-element-icon mr-4">
      <v-icon :color="color" size="50" class="mr-0">
        {{ icon }}
      </v-icon>
    </div>
    <div class="material-stats-card-element-text">
      <h4 v-if="title">
        {{ title }}
      </h4>
      <h5 v-if="text">
        {{ text }}
      </h5>
      <h2 v-if="value">
        {{ value }}
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MaterialStatsCard',

  inheritAttrs: false,

  props: {
    color: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: undefined,
    },
    text: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      default: undefined,
    },
  },
}
</script>

<style scoped>
.material-stats-card-element {
  display: flex;
  border-radius: 4px;
  padding: 10px;
}

.material-stats-card-element-icon {
  padding: 5px;
  background-color: #fff;
  border-radius: 4px;
}

.material-stats-card-element-text {
  color: #fff;
}
</style>
